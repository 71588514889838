import React from "react"
import { Layout } from "../layouts/Layout"
import SEO from "../components/seo"

const Policy = () => {
    return (
        <Layout ModalTitle={""}>
            <SEO title="Home" />
            <div>
                <h2>Όροι χρήσης</h2>

                <ul>
                    <li>Σκοπός αυτού του εγγράφου είναι η παράθεση των όρων και προϋποθέσεων που διέπουν τη χρήση του ιστοχώρου <a href="http://www.artiapix.gr">www.artiapix.gr</a>, που είναι ο διαδικτυακός τόπος της εταιρείας Artiapix (στο εξής "ο ιστοχώρος").&nbsp;</li>
                    <li>Η χρήση του ιστοχώρου προϋποθέτει την πλήρη και ανεπιφύλακτη αποδοχή των αναφερόμενων όρων, οι οποίοι ισχύουν για το σύνολο του περιεχομένου αυτού.&nbsp;</li>
                </ul>

                <h2>Παροχή πληροφοριών και ενημέρωσης</h2>

                <ul>
                    <li>Η Artiapix καταβάλει κάθε δυνατή προσπάθεια, ώστε οι πληροφορίες και το σύνολο του περιεχομένου του ιστοχώρου να διέπονται από ορθότητα, ακρίβεια, πληρότητα, επάρκεια, σαφήνεια, διαθεσιμότητα και χρονική εγγύτητα, δεν εγγυάται όμως και δεν ευθύνεται για την επίτευξη της ύπαρξης των στοιχείων αυτών.&nbsp;</li>
                    <li>Σε καμία περίπτωση, συμπεριλαμβανομένης και αυτής της αμέλειας, δεν υφίσταται ευθύνη της Artiapix για οποιουδήποτε είδους ζημία τυχόν προκληθεί στο χρήστη/επισκέπτη του ιστοχώρου λόγω&nbsp; της χρήσης αυτού.&nbsp;</li>
                    <li>Οι πληροφορίες και τα λοιπά στοιχεία που διατίθενται στους χρήστες/επισκέπτες μέσω του ιστοχώρου, στον βαθμό που δεν αφορούν την ίδια την Artiapix, συλλέγονται από την Artiapix από προσιτές στο κοινό πηγές και παρέχονται προς τους χρήστες/επισκέπτες του ιστοχώρου αποκλειστικά και μόνο για ενημερωτικούς σκοπούς.</li>
                    <li>Οι πληροφορίες και τα λοιπά στοιχεία μπορεί να περιέχουν γενικές περιγραφές σχετικά με τις τεχνικές δυνατότητες επιμέρους προϊόντων ή υπηρεσιών, οι οποίες μπορεί να μην είναι διαθέσιμες σε ορισμένες περιπτώσεις (π.χ. λόγω αλλαγών σε προϊόντα). Η απαιτούμενη απόδοση και τα χαρακτηριστικά του προϊόντος ή της υπηρεσίας, επομένως, θα συμφωνείται αμοιβαία σε κάθε περίπτωση κατά το χρόνο της αγοράς.</li>
                    <li>Στον χρήστη/επισκέπτη εναπόκειται αποκλειστικά η ευθύνη για την χρήση, αξιολόγηση, εκτίμηση και αξιοποίηση των εκάστοτε παρεχομένων πληροφοριών. Η λήψη οποιωνδήποτε επιχειρηματικών ή άλλων αποφάσεων με αφορμή τις πληροφορίες αυτές γίνεται με αποκλειστική ευθύνη του χρήστη/επισκέπτη και η Artiapix δεν υποχρεούται να καλύψει ή ανορθώσει ζημίες ή διαφορές από τη χρήση των παρεχομένων μέσω του ιστοχώρου πληροφοριών.</li>
                    <li>Ο χρήστης/επισκέπτης έχει την ευθύνη πρόσβασης στις υπηρεσίες του ιστοχώρου και η σχετική πρόσβαση μπορεί να απαιτεί την καταβολή τελών σε τρίτους φορείς (π.χ. παροχείς υπηρεσιών internet, χρέωση χρόνου παραμονής στο internet). Ο χρήστης/επισκέπτης έχει την αποκλειστική ευθύνη πληρωμής των σχετικών τελών. Επίσης, ο χρήστης/επισκέπτης είναι αποκλειστικά υπεύθυνος για τον προσωπικό εξοπλισμό του με τα απαραίτητα τεχνολογικά μέσα που του επιτρέπουν την πρόσβαση στις υπηρεσίες του ιστοχώρου.</li>
                </ul>

                <h2>Δεσμοί (links) προς ιστοσελίδες και δικτυακούς τόπους τρίτων</h2>

                <ul>
                    <li>Μέσω του ιστοχώρου είναι δυνατό να παρέχεται η δυνατότητα στους χρήστες/επισκέπτες να διοδεύονται μέσω ειδικών συνδέσμων (links, hyperlinks, banners) σε ιστοσελίδες ή δικτυακούς τόπους (web-sites) τρίτων, το περιεχόμενο των οποίων διαμορφώνεται με αποκλειστική ευθύνη των προσώπων αυτών.&nbsp;</li>
                    <li>Η Artiapix δεν εγγυάται τη διαθεσιμότητά τους και δεν εγκρίνει ούτε ευθύνεται για το περιεχόμενο, την ορθότητα, νομιμότητα, πληρότητα, επικαιρότητα και ακρίβεια των πληροφοριών ούτε για την ποιότητα και τις ιδιότητες των προϊόντων ή υπηρεσιών, τα οποία διατίθενται από τα πρόσωπα αυτά μέσω των ανωτέρω ιστοσελίδων ή δικτυακών τόπων.&nbsp;</li>
                    <li>Η Artiapix δεν έχει καμία ευθύνη για την επικοινωνία του χρήστη/επισκέπτη με τρίτους παροχείς προϊόντων ή υπηρεσιών που τυχόν διαφημίζονται στον ιστοχώρο και για την οποιαδήποτε τυχόν εμπορική συναλλαγή που μπορεί να προκύψει από τη μεταξύ τους σχέση.&nbsp;&nbsp;</li>
                </ul>

                <h2>Δικαιώματα πνευματικής ιδιοκτησίας - Εμπορικά σήματα</h2>

                <ul>
                    <li>Το περιεχόμενο του ιστοχώρου (πληροφοριακό υλικό κάθε μορφής, δεδομένα,&nbsp; γραφικά, εμπορικά σήματα, επωνυμίες, λογότυπα κλπ.) αποτελεί ιδιοκτησία της Artiapix ή του εκάστοτε δικαιούχου αυτού, η οποία προστατεύεται από τις διατάξεις της ελληνικής και κοινοτικής νομοθεσίας.</li>
                    <li>Απαγορεύεται οποιαδήποτε αναδημοσίευση, μετάδοση, μεταβίβαση, αναπαραγωγή, διανομή, παρουσίαση, σύνδεση, φόρτωση (download) ή με άλλον τρόπο εκμετάλλευση, συνολικά ή μερικά, του περιεχομένου του ιστοχώρου με οποιονδήποτε τρόπο ή μέσο για εμπορικούς ή άλλους σκοπούς, χωρίς την προηγούμενη έγγραφη άδεια της Artiapix.&nbsp; Εξαιρείται η περίπτωση της μεμονωμένης αποθήκευσης ενός και μόνου αντιγράφου τμήματος του περιεχομένου σε έναν απλό προσωπικό ηλεκτρονικό υπολογιστή, για προσωπική και όχι δημόσια ή εμπορική χρήση και χωρίς απαλοιφή της ένδειξης προέλευσής τους από τον ιστοχώρο&nbsp;<a href="http://www.artiapix.gr">www.artiapix.gr</a>, χωρίς να θίγονται με κανένα τρόπο τα σχετικά δικαιώματα πνευματικής και βιομηχανικής ιδιοκτησίας.</li>
                </ul>

                <h2>Λειτουργία του ιστοχώρου</h2>

                <ul>
                    <li>Το Internet δεν είναι ασφαλές περιβάλλον και η Artiapix δεν εγγυάται ότι οι λειτουργίες του ιστοχώρου θα είναι αδιάλειπτες ή απαλλαγμένες από κάθε είδους σφάλματα και ιούς και δεν ευθύνεται για οποιαδήποτε απώλεια δεδομένων ή άλλη ζημία του χρήστη/επισκέπτη ή τρίτων, οφειλόμενη είτε σε χρήση / αντιγραφή / φόρτωση (download) είτε σε αλλοίωση ή μόλυνση με ιούς ή άλλες μη επιτρεπόμενες παρεμβάσεις τρίτων σε αρχεία και πληροφορίες που διατίθενται μέσω του ιστοχώρου. Ο χρήστης/επισκέπτης είναι αποκλειστικά υπεύθυνος για την προστασία του συστήματός του από ιούς.</li>
                </ul>

                <h2>Τροποποίηση όρων χρήσης</h2>

                <ul>
                    <li>Η Artiapix διατηρεί το δικαίωμα να τροποποιεί οποτεδήποτε, τους παρόντες όρους χρήσης και το περιεχόμενο του ιστοχώρου, οι δε χρήστες/επισκέπτες οφείλουν κάθε φορά να ελέγχουν για ενδεχόμενες αλλαγές και εφόσον εξακολουθούν να κάνουν χρήση του ιστοχώρου θα τεκμαίρεται ότι έχουν αποδεχθεί τις τροποποιήσεις.&nbsp;</li>
                </ul>

                <h2>Εφαρμοστέο δίκαιο και λοιποί όροι</h2>

                <ul>
                    <li>Οι ανωτέρω όροι και προϋποθέσεις χρήσης του <a href="http://www.artiapix.gr">www.artiapix.gr</a>, καθώς και οποιαδήποτε τροποποίηση τους, διέπονται και συμπληρώνονται από το ελληνικό δίκαιο, το δίκαιο της Ευρωπαϊκής Ένωσης και τις σχετικές διεθνείς συνθήκες. Καμία τροποποίηση των όρων και των προϋποθέσεων χρήσης του <a href="http://www.artiapix.gr">www.artiapix.gr</a> δε θα λαμβάνεται υπόψη εάν δεν έχει διατυπωθεί εγγράφως και δεν έχει ενσωματωθεί σε αυτό το έγγραφο.</li>
                </ul>


            </div>
        </Layout >
    )
}

export default Policy
